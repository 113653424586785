<template>
  <div class="content-wrapper">

    <ErrorModal
      :modalShow="modalError.showModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />

    <b-card no-body>
      <b-overlay :show="loadingShow" rounded="lg" opacity="0.6" class="w-100">
        <EmpresaHeader :empresa="empresa" @confirmed="resolveRoute"/>
        <main class="p-2">
          <alertaTodasAbas :situacao="situacaoCadastro" :show="situacaoCadastro == 'Incompleto'" />
          <section>
            <b-row class="mb-1">
              <b-col 
                lg="4"
              >
                <b-form-group 
                  label="Unidade Operacional"
                  label-for="unidadeOperacional"
                  label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="unidadeOperacional">
                        {{ this.atendimento.unidadeOperacionalDescricao }}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group 
                  label="Região"
                  label-for="regiao"
                  label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="regiao">
                        {{this.atendimento.regiaoDescricao }}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">

                <b-form-group 
                  label="Departamento Regional"
                  label-for="departamentoRegional"
                  label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="departamentoRegional">
                        {{ this.atendimento.departamentoRegionalDescricao }}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" v-if="this.$can(actions.VISUALIZAR, subjects.CAMPANHA_VACINA_EMPRESA)">
                <label class="label_sub_titulo">Empresa com valor diferenciado?</label>
                  <div class="radio-inline-spacing-valor">
                    <b-form-radio
                        v-model="atendimento.empresaPossuiValorDiferenciado"
                        name="radio-valor-diferenciado"
                        :value="true"
                        :disabled="true"
                    >
                        <p class="corRadio">Sim</p>
                    </b-form-radio>
                    <b-form-radio
                        v-model="atendimento.empresaPossuiValorDiferenciado"
                        name="radio-valor-diferenciado"
                        :value="false"
                        :disabled="true"
                    >
                        <p class="corRadio">Não</p>
                    </b-form-radio>
                  </div>
              </b-col>
              <b-col lg="4">
                <label class="label_sub_titulo">Possui Convênio?</label>
                  <div class="radio-inline-spacing-valor">
                    <b-form-radio
                        v-model="atendimento.possui_convenio"
                        name="radio-possui-convenio"
                        :value="true"
                        :disabled="true"
                    >
                        <p class="corRadio">Sim</p>
                    </b-form-radio>
                    <b-form-radio
                        v-model="atendimento.possui_convenio"
                        name="radio-possui-convenio"
                        :value="false"
                        :disabled="true"
                    >
                        <p class="corRadio">Não</p>
                    </b-form-radio>
                  </div>
              </b-col>
              <b-col lg="4">
                <label class="label_sub_titulo">Mesmo endereço do cadastro para vacinação?</label>
                  <div class="radio-inline-spacing-valor">
                    <b-form-radio
                        v-model="atendimento.mesmoEndereco"
                        name="radio-mesmo-endereco"
                        :value="true"
                        :disabled="true"
                    >
                        <p class="corRadio">Sim</p>
                    </b-form-radio>
                    <b-form-radio
                        v-model="atendimento.mesmoEndereco"
                        name="radio-mesmo-endereco"
                        :value="false"
                        :disabled="true"
                    >
                        <p class="corRadio">Não</p>
                    </b-form-radio>
                  </div>
              </b-col>
            </b-row>
          </section>
          <section v-if="!atendimento.mesmoEndereco">
            <b-row>
              <b-col lg="12">
                <p class="label_titulo">Endereço de vacinação</p>
              </b-col>
              <b-col lg="4">
                <b-form-group label="CEP"
                label-for="cep"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div v-if="this.enderecoVacinal.cep != '-'" id="cep" class="label_empresa">
                        {{ this.enderecoVacinal.cep | VMask('#####-###') }}
                    </div>
                    <div v-else id="cep" class="label_empresa">
                        {{ this.enderecoVacinal.cep }}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Rua/Avenida"
                label-for="rua-avenida"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="rua-avenida" class="label_empresa">
                        {{this.enderecoVacinal.logradouro}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Número"
                label-for="numero"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="numero" class="label_empresa">
                        {{this.enderecoVacinal.numero}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Ponto de Referência"
                label-for="ponto-referencia"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="ponto-referencia" class="label_empresa">
                        {{this.enderecoVacinal.ponto_referencia}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Bairro"
                label-for="bairro"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="bairro" class="label_empresa">
                        {{this.enderecoVacinal.bairro}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Cidade"
                label-for="cidade"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="cidade" class="label_empresa">
                        {{this.enderecoVacinal.cidadeSelecionadaNome}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Estado"
                label-for="estado"
                label-class="label_sub_titulo"
                >
                  <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                    <div id="estado" class="label_empresa">
                        {{this.enderecoVacinal.estadoSelecionadoNome}}
                    </div>
                  </vue-perfect-scrollbar>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <section class="buttons mt-3">
            <b-button id="redireciona-edicao" 
                v-if="canEdit"
                @click.prevent="redirectToEdit()"
                variant="custom-blue"
            >
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar</span>
            </b-button>
          </section>
        </main>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BCard, BTab, BTabs, BButton, BFormGroup, BCol, BRow, BFormRadio, BOverlay } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmpresaHeader from '@/views/components/custom/page-header/Header-empresa.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import alertaTodasAbas from '@/views/components/custom/timed-alert/alertaAbasIncompletasFechavel.vue'
import { getIdEmpresa } from '@core/utils/store/getStore';
import { setIdEmpresa } from '@core/utils/store/setStore';

export default {
  computed: {
    subjects() {
      return subjects
    },
    actions() {
      return actions
    }
  },

  title: 'Detalhes empresa',

  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    BFormGroup,
    BCol, 
    BRow,
    BFormRadio,
    EmpresaHeader,
    VuePerfectScrollbar,
    ErrorModal,
    alertaTodasAbas,
    BOverlay
  },

  data() {
    return {
      situacaoCadastro: 'Completo',
      loadingShow: true,
      modalError: {
        showModal: false,
        caseModal: 'idInexistente'
      },
      canEdit: false,
      enderecoVacinal: {
        cep: "-",
        numero: "-",
        ponto_referencia: "-",
        cidadeSelecionadaNome: "-",
        estadoSelecionadoNome: "-",
        logradouro: "-",
        bairro: "-"
      },
      atendimento: {
        unidadeOperacionalDescricao: "-",
        regiaoDescricao: "-",
        departamentoRegionalDescricao: "-",
        valor_diferenciado: false,
        tipoPrecoDescricao: "-",
        possui_convenio: false,
        mesmoEndereco: true
      },
      empresa: {
        id_empresa: '',
        nome: '',
        gerenciarColaboradores: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      }
    }
  },

  mounted() {
    if((getIdEmpresa() === null || typeof getIdEmpresa() == 'undefined') && typeof this.$route.params.idEmpresa == "undefined") {
        this.openModalError();
        return;
    }

    if(typeof this.$route.params.idEmpresa != "undefined"){
        if (this.$route.params.idEmpresa !== getIdEmpresa()) {
            setIdEmpresa(this.$route.params.idEmpresa);
        }
    }

    this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
      this.setDadosParaVisualizar(data)
    })
    this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
  },

  methods: {
    redirectToEdit() {
      this.$router.push(
        { 
          name: 'empresa-edit', 
          params: {
              idEmpresa: this.empresa.id_empresa
          }
        }
      );
    },

    openModalError() {
      this.modalError.showModal = true;
    },

    setDadosParaVisualizar(dados){
      this.situacaoCadastro = dados['situacao_cadastro']
      this.empresa.id_empresa = getIdEmpresa()
      this.atendimento.unidadeOperacionalDescricao = this.setUnidadeOperacionalDescricao(dados)
      this.atendimento.regiaoDescricao = this.setUnidadeOperacionalRegicaoDescricao(dados)
      this.atendimento.departamentoRegionalDescricao = dados['departamento_descricao'] ? dados['departamento_descricao'] : "-"
      this.atendimento.valor_diferenciado = dados['valor_diferenciado'] ? dados['valor_diferenciado'] : false
      this.atendimento.empresaPossuiValorDiferenciado = dados['empresa_possui_valor_diferenciado'] ? dados['empresa_possui_valor_diferenciado'] : false
      this.atendimento.tipoPrecoDescricao = dados['tipo_preco_descricao'] ? dados['tipo_preco_descricao'] : "-"
      this.atendimento.possui_convenio = dados['possui_convenio'] ? dados['possui_convenio'] : false
      this.empresa.nome = dados['cnpj'] ? dados['nome_fantasia'] : dados['nome']
      this.empresa.gerenciarColaboradores = dados['pode_gerenciar_colaboradores']
      this.preenchimentoEnderecoVacinal(dados)
      if(!Object.values(this.enderecoVacinal).every(x => x == "-")){
        this.atendimento.mesmoEndereco = false;
      }
      this.loadingShow = false
    },

    setUnidadeOperacionalDescricao(dados){
      // const unidade = dados['enderecos'][0]['unidade_atendimento']
      const unidade = false
      return unidade ? unidade.descricao : "-";
    },

    setUnidadeOperacionalRegicaoDescricao(dados){
      // const regiao = dados['enderecos'][0]['unidade_atendimento']['regiao']
      const regiao = false;
      return regiao ? regiao.descricao : "-";
    },

    preenchimentoEnderecoVacinal(dados){
      this.enderecoVacinal.cep = dados['cepVacinal'] ? dados['cepVacinal'] : "-"
      this.enderecoVacinal.numero = dados['numeroVacinal'] ? dados['numeroVacinal'] : "-"
      this.enderecoVacinal.ponto_referencia = dados['pontoReferenciaVacinal'] ? dados['pontoReferenciaVacinal'] : "-"
      this.enderecoVacinal.cidadeSelecionadaNome = dados['cidadeVacinalNome'] ? dados['cidadeVacinalNome'] : "-"
      this.enderecoVacinal.estadoSelecionadoNome = dados['estadoVacinalUF'] ? dados['estadoVacinalUF'] : "-"
      this.enderecoVacinal.logradouro = dados['logradouroVacinal'] ? dados['logradouroVacinal'] : "-"
      this.enderecoVacinal.bairro = dados['bairroVacinal'] ? dados['bairroVacinal'] : "-"
    },

    redirecionarLista() {
      this.$router.push({ name: 'empresa-list' });
    },

    resolveRoute(route){
      if(route != this.$route.name){
        this.$router.push(
          { 
              name: route, 
              params: {
                empresa: this.empresa.id_empresa
              }
          }
        );
      }
    },
    verifyPermissions(action) {
          const permissionUO = this.$can(actions[action], subjects.EMPRESAS_UO)
          const permissionDR = this.$can(actions[action], subjects.EMPRESAS_DR)
          const permissionAR = this.$can(actions[action], subjects.EMPRESAS_AR)

          return [permissionUO, permissionDR, permissionAR].includes(true);
      }
  }

}
</script>
<style lang="scss" scoped>
  .label_titulo{
      font-size: 18px;
      font-weight: 700;
  }
  .label_sub_titulo{
      font-size: 14px;
      font-weight: 600;
  }
  .label_empresa{
      font-size: 14px;
  }
  .altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
  }
  .radio-inline-spacing-valor {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
      margin-bottom: 0.2rem;
    }
  }
  .corRadio{
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
  }

  @media (max-width: 767px) { 
    .centralizar_responsivo{
        text-align: center;
        justify-content: center;
    }
  }
</style>
